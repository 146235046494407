.Numeric {
  font-size: 14px;
  font-weight: 600;
  color: $color-dark-grey;
}

.Numeric--green {
  color: $color-green;
  font-weight: bold;
}

.Numeric--red {
  color: $color-red;
  font-weight: bold;
}

.Numeric--pink {
  color: $color-blue;
  font-weight: bold;
}
