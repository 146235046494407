.StationCard {
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
  padding: 30px 26px;
}
.StationCard__img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 4px;
}

.StationCard__img img {
  width: 100%;
}

.StationCard__name {
  font-size: 20px;
  font-weight: bold;
  color: $color-black;
  margin-bottom: 28px;
}

.StationCard__label {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 600;
  letter-spacing: 1.8px;
  color: $color-dark-grey;
}

.StationCard__value {
  font-size: 16px;
  font-weight: bold;
  color: $color-black;
}

.StationCard__value:not(:last-child) {
  margin-bottom: 12px;
}
