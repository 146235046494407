.Select {
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
  border-radius: 5px;
  text-align: left;
  color: $color-black;
  box-sizing: border-box;
  background-color: $color-input;
  min-width: 0;
  width: 18vw;
  max-width: 328px;
  position: relative;
  padding: 6px 12px;
  box-shadow: inset 0px 0px 0px 0px transparent;
  transition-property: background-color, box-shadow;
  transition-timing-function: $easing-default;
  transition-duration: $speed-off;
}

.Select--filled {
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0px 0px 0px 1px #e5e5e5;
  transition-duration: $speed-on;
}

.Select--opened {
  background-color: $color-white;
  box-shadow: inset 0px 0px 0px 2px $color-blue;
  transition-duration: $speed-on;
}

.Select__value {
  font-size: 14px;
  display: inline-flex;
  flex: 1;
}

.Select__value--withLabel {
  padding-top: 16px;
}

.Select__dropdownIcon {
  margin-left: auto;
  font-size: 16px;
  line-height: 48px;
  user-select: none;
  pointer-events: none;
  position: absolute;
  right: 22px;
  color: $color-mid-grey;
}

.Select__label {
  flex: 1;
  font-size: 14px;
  color: $color-mid-grey;
  white-space: nowrap;
}

.Select--filled .Select__label {
  font-size: 12px;
  position: absolute;
  top: 8px;
}

.Select__options {
  max-height: 20vh;
  left: 0;
  top: calc(100% + 2px);
  right: 0;
  margin: 0;
}
