.VersusTable__totalRow {
  border-top: none;
}

.VersusTable__totalValue {
  font-size: 12px;
  font-weight: 600;
  color: $color-dark-grey;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding-left: 0;
}

.VersusTable__actions {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding-right: 0;
}

.VersusTable__progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.VersusTable__progress > *:nth-child(2) {
  margin-top: 10px;
}

.Table--compact .VersusTable__progress > *:nth-child(2) {
  margin-top: 4px;
}

.VersusTable--empty {
  line-height: 55vh;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: $color-mid-grey;
}
