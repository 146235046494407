.Score {
  font-size: 18px;
  font-family: $typography-metric;
  color: $color-black;
}

.Score--pink {
  color: $color-blue;
}

.Score--grey {
  color: $color-mid-grey;
}

.Score--orange {
  color: $color-orange;
}
