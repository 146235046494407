$search-width: 45vw;

.SearchTitles {
  position: relative;
  margin-right: 16px;
}

.SearchTitles__Input {
  width: 20vw;
  transition-property: width;
  transition-duration: $speed-out;
  transition-timing-function: $easing-default;
}

.SearchTitles__Input--focused {
  width: $search-width;
  max-width: none;
}

.SearchTitles__Dropdown {
  z-index: 1000;
  max-height: 50vh;
  width: $search-width;
  position: absolute;
  left: -8px;
  top: 46px;
}

.SearchTitles__Dropdown_Item__Label {
  line-height: initial;
}

.SearchTitles__Dropdown_Item__Label-smWave {
  color: $color-blue;
  font-weight: 600;
}

.SearchTitles__img {
  min-width: 36px;
  min-height: 36px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 3px;
}
