.Table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: 14px;
  font-weight: 500;
  color: $color-dark-grey;
  user-select: none;
}

@import './table.utils';
@import './TableCell/TableCell';
@import './TableCellText/TableCellText';
@import './TableRow/TableRow';
@import './TableCellSortableText/TableCellSortableText';
@import './TableRowTitle/TableRowTitle';
@import './TableHead/TableHead';
