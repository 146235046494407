.GroupsTabRow {
  justify-content: center;
  border-top: none;
}

.GroupsTabRow__cell {
  text-transform: uppercase;
  font-size: 12px;
  color: $color-dark-grey;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1.8px;
  border-bottom: 4px solid transparent;
  white-space: nowrap;
}

.GroupsTabRow__cell--active {
  border-bottom: 4px solid $color-blue;
}
