.Chip--light-grey {
  background-color: $color-hover;
  color: $color-dark-grey;
}

.Chip--light-grey--active,
.Chip--light-grey--clickable:hover,
.Chip--light-grey:focus {
  background-color: mix($color-hover, $color-black, 90%);
}
