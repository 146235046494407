.charts-tooltip {
  background-color: rgb(51, 51, 51);
  color: white;
  text-align: center;
  font-size: 14px;
  font-family: 'Open sans';
  padding: 5px;
  border-radius: 3px;
  font-weight: 700;
}

@import './GeoMercator/GeoMercator.scss';
@import './Donut/Donut.scss';
@import './MultipleLinesChart/MultipleLineChartTooltip.scss';
