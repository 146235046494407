.TableHead {
}

.TableHead--sticky {
  background-color: $color-white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: sticky;
  z-index: 20;
  top: 0;
  padding: 0px $padding_page;
  margin: 0px $negative_padding_page;
}

@include responsive-tablet {
  .TableHead--sticky {
    padding: 0px $padding_small_page;
    margin: 0px $negative_padding_small_page;
  }
}
