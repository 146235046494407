.Title {
  margin-left: 12px;
  height: 100%;
  flex: 1;
}

.Title,
.Title--row {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 0;
}

.Title--column {
  flex-direction: column;
  height: auto;
  margin-left: 0;
}

.Title__name {
  @include text-ellipsis;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 100%;
}

.Title__name:nth-child(2) {
  margin-left: 4px;
}

.Title__name--column {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 4px 0;
}

.Title__name b {
  font-weight: bold;
}

.Title__link {
  display: none;
  margin-left: 8px;
}

.Title:hover {
  .Title__link {
    display: block;
  }
  .TitleType {
    display: none;
  }
}

.Title__actions {
  display: flex;
  align-items: center;
}

.Title__actions--column {
  margin-top: 8px;
}

.Title__link:hover {
  color: $color-blue;
}

.Title__link--persist {
  display: block;
}

.Title__tooltipName {
  font-weight: 600;
  margin-right: 4px;
}

.Title__tooltipArtist {
  text-transform: capitalize;
}

.Title__selection:not(:first-child) {
  margin-top: 4px;
}
