.GeoMercator__legend__group {
  width: 100%;
  display: inline-flex;
  padding-bottom: 15px;
  justify-content: space-around;
}

.GeoMercator__legend {
  display: inline-flex;
  flex-direction: row;
  width: 50%;
  max-width: 240px;
}

.GeoMercator__step {
  flex: 1;
  &:first-child {
    .GeoMercator__bar {
      border-radius: 12px 0 0 12px;
    }
  }

  &:last-child {
    .GeoMercator__bar {
      border-radius: 0 12px 12px 0;
    }
  }
}

.GeoMercator__label {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  width: 100%;
  display: block;
}

.GeoMercator__bar {
  height: 12px;
  background-color: red;
  border: 1px solid $color-white;
}
