.SelectionRenameModal__title {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-black;
}

.SelectionRenameModal__input {
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 24px;
}

.SelectionRenameModal__actions {
  display: flex;
  justify-content: flex-end;
}

.SelectionRenameModal__actions > *:not(:last-child) {
  margin-right: 12px;
}
