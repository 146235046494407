.Button {
  @extend %reset-button-styles;
  display: inline-flex;
  align-items: center;
  background-color: $color-blue;
  color: $color-white;
  padding: 15px;
  border-radius: 6px;
  padding: 14px 22px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 1;
  transition-property: opacity;
  transition-duration: $speed-off;
  transition-timing-function: $easing-default;
  &:hover {
    opacity: 0.8;
    transition-duration: $speed-on;
  }
}

.Button--outlined {
  background-color: transparent;
  color: $color-dark-grey;
}

.Button--pink {
  color: $color-blue;
}

.Button--grey {
  background-color: $color-light-grey;
  color: $color-dark-grey;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}

.Button__icon {
  margin-right: 8px;
}

.Button--disabled {
  opacity: 0.6;
  &:hover {
    opacity: 0.6;
  }
}
