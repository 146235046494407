.ExpertFilters {
  display: flex;
  flex-direction: column;
  width: 18vw;
  max-width: 400px;
  padding: 18px 14px;
  border-radius: 3px;
  border: solid 1px $color-light-grey;
  margin-right: 24px;
  align-self: flex-start;
}

.ExpertFilters__title {
  font-size: 16px;
  font-weight: bold;
  color: $color-dark-grey;
  margin-bottom: 12px;
}

.ExpertFilters__add {
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  padding: 14px;
  font-size: 14px;
  font-weight: bold;
  color: $color-blue;
  border-radius: 3px;
  border: solid 1px;
  border-style: dashed;
  margin-top: 24px;
}
