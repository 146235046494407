input[type='checkbox'] {
  display: none;
}

.CheckboxInput {
  display: flex;
  flex-direction: row;

  cursor: pointer;
  .checkbox__label {
    font-family: $typography-base;
    color: $color-dark-grey;
    font-size: 1rem;
    cursor: pointer;
    user-select: none;
  }
  .checkbox__square {
    position: relative;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    border-radius: 5px;

    &--unchecked:after {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(1rem - 2px);
      height: calc(1rem - 2px);
      border-radius: 4px;
      content: ' ';
      background-color: $color-white;
      border: 1px solid $color-mid-grey;
      color: $color-white;
    }

    &--checked:after {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(1rem - 2px);
      height: calc(1rem - 2px);
      border-radius: 4px;
      content: '\2713';
      text-align: center;
      font-size: 0.9em;
      background-color: $color-blue;
      border: 1px solid $color-blue;
      color: $color-white;
    }
  }
}
