.big-input {
  margin: 16px 0;

  .input__container {
    width: 100%;
    max-width: 100%;
  }
}

.input {
  &__container {
    display: flex;
    align-items: center;
    position: relative;
    width: 18vw;
    height: 48px;
    padding: 6px 12px;
    border-radius: 5px;
    max-width: 328px;

    .label {
      position: absolute;
      flex: 1;
      color: $color-mid-grey;
      white-space: nowrap;
      pointer-events: none;
      font-size: 14px;
    }

    input {
      border: none;
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      outline: none;
      padding-top: 16px;
      padding-left: 0;
      font-size: 14px;
      background-color: transparent;
      color: $color-black;
    }

    &--default {
      background-color: $color-input;
      box-shadow: inset 0px 0px 0px 0px transparent;
      transition-property: background-color, box-shadow;
      transition-timing-function: $easing-default;
      transition-duration: $speed-off;

      &-filled {
        box-shadow: inset 0px 0px 0px 1px #e5e5e5;
        background-color: rgba(255, 255, 255, 0.6) !important;
        transition-duration: $speed-on;
        .label {
          top: 8px;
          font-size: 12px;
        }
      }

      &-focused {
        box-shadow: inset 0px 0px 0px 2px $color-blue;
        background-color: rgba(255, 255, 255, 1) !important;
        transition-duration: $speed-on;

        .label {
          top: 8px;
          font-size: 12px;
        }
      }
    }

    &--error {
      box-shadow: inset 0px 0px 0px 2px $color-red;
      input {
      }
    }

    &--disabled {
      background-color: rgb(246, 246, 246);
      input {
        cursor: not-allowed;
        user-select: none;
        box-shadow: inset 0px 0px 0px 1px $color-mid-grey;

        &:hover {
          box-shadow: inset 0px 0px 0px 1px rgb(111, 111, 111);
        }
      }

      label,
      input {
        color: $color-mid-grey;
      }
    }

    .color-disabled {
      color: $color-mid-grey;
    }

    .helper {
      &--display,
      &--default,
      &--error,
      &--disabled {
        user-select: none;
        position: absolute;
        font-size: 12px;
        bottom: -16px;
        left: 16px;
      }

      &--default {
        color: $color-mid-grey;
        opacity: 0;
      }

      &--display {
        color: $color-blue;
        opacity: 1;
      }

      &--error {
        color: $color-red;
      }

      &--disabled {
        color: $color-mid-grey;
      }
    }

    .input-icon {
      font-size: 10px;
      line-height: 48px;
      user-select: none;
      pointer-events: none;
      position: absolute;
      right: 22px;
      color: $color-mid-grey;
    }

    .clear-content-icon {
      line-height: 14px;
      font-size: 14px;
      color: $color-mid-grey;
      cursor: pointer;
      pointer-events: all;
    }
  }
}
