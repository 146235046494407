@import './Adornment/Adornment';
@import './AutoSizeInput/AutoSizeInput';
@import './SelectMultipleCard/SelectMultipleCard';
@import './Chip/Chip';
@import './DropdownList/DropdownList';
@import './ListItem/ListItem';
@import './ListItem/ListItemText';
@import './OutlinedInput/outlined-input.utils';
@import './OutlinedInput/OutlinedInput';
@import './VirtualList/VirtualList';

$OutlinedInput-lineHeight: 21px;
$OutlinedInput-heightMedium: 48px;
$OutlinedInput-heightSmall: 36px;
$Chip-heightMedium: 32px;
$Chip-heightSmall: 24px;
$OutlinedInput-horizontalSpacing: 16px;

$OutlinedInput-verticalSpacing-heightMedium: (
    $OutlinedInput-heightMedium - $OutlinedInput-lineHeight
  ) / 2;

$OutlinedInput-verticalSpacing-heightSmall: (
    $OutlinedInput-heightSmall - $OutlinedInput-lineHeight
  ) / 2;

$SelectMultiple-chipsSpacingMedium: (
  ($OutlinedInput-heightMedium - $Chip-heightMedium) / 2
);

$SelectMultiple-chipsSpacingSmall: (
  ($OutlinedInput-heightSmall - $Chip-heightSmall) / 2
);

@mixin SelectMultiple-chipsSize($size) {
  margin-left: -#{$size};
  margin-top: -#{$size};
  width: calc(100% + #{$size});
  padding: $size 0;
}

.SelectMultiple__chips {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.SelectMultiple__chips--medium {
  @include SelectMultiple-chipsSize($SelectMultiple-chipsSpacingMedium);
}

.SelectMultiple__chips--small {
  @include SelectMultiple-chipsSize($SelectMultiple-chipsSpacingSmall);
}

@mixin SelectMultiple-chipSize($size) {
  max-width: calc(100% - #{$size});
  margin-top: $size;
  margin-left: $size;
}

.SelectMultiple__chip {
  flex: none;
}

.SelectMultiple__chip--medium {
  @include SelectMultiple-chipSize($SelectMultiple-chipsSpacingMedium);
}

.SelectMultiple__chip--small {
  @include SelectMultiple-chipSize($SelectMultiple-chipsSpacingSmall);
}

.SelectMultiple__searchChip {
  @extend .SelectMultiple__chip;
  min-width: 3em;
}

.SelectMultiple__searchChip--medium {
  @extend .SelectMultiple__chip--medium;
}

.SelectMultiple__searchChip--small {
  @extend .SelectMultiple__chip--small;
}

.SelectMultiple__searchChip--hidden {
  min-width: 0;
  max-width: 0;
  margin-left: 0;
  padding: 0;
  overflow: hidden;
}
