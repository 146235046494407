// 110% is enough for snackbars larger than 70px (when 1rem = 7px).
$Snackbar-outOffset-horizontal: 110%;

// 500px is enough to support up to 10 snackbars 48px high.
$Snackbar-outOffset-vertical: 500px;

@mixin Snackbar-defaultTransform {
  transform: translate3d(0, 0, 0);
}

// We need to use the default class names as we cannot pass our own without
// reimplementing the React transition component.

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
  animation-name: Snackbar-slideIn-toRight;
}

@keyframes Snackbar-slideIn-toRight {
  from {
    transform: translate3d(-$Snackbar-outOffset-horizontal, 0, 0);
    visibility: visible;
  }

  to {
    @include Snackbar-defaultTransform;
  }
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
  animation-name: Snackbar-slideOut-toLeft;
}

@keyframes Snackbar-slideOut-toLeft {
  from {
    @include Snackbar-defaultTransform;
  }

  to {
    visibility: hidden;
    transform: translate3d(-$Snackbar-outOffset-horizontal, 0, 0);
  }
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
  animation-name: Snackbar-slideIn-toLeft;
}

@keyframes Snackbar-slideIn-toLeft {
  from {
    transform: translate3d($Snackbar-outOffset-horizontal, 0, 0);
    visibility: visible;
  }

  to {
    @include Snackbar-defaultTransform;
  }
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
  animation-name: Snackbar-slideOut-toRight;
}

@keyframes Snackbar-slideOut-toRight {
  from {
    @include Snackbar-defaultTransform;
  }

  to {
    visibility: hidden;
    transform: translate3d($Snackbar-outOffset-horizontal, 0, 0);
  }
}

.Toastify__slide-enter--top-center {
  animation-name: Snackbar-slideIn-toBottom;
}

@keyframes Snackbar-slideIn-toBottom {
  from {
    transform: translate3d(0, -$Snackbar-outOffset-vertical, 0);
    visibility: visible;
  }

  to {
    @include Snackbar-defaultTransform;
  }
}

.Toastify__slide-exit--top-center {
  animation-name: Snackbar-slideOut-toTop;
}

@keyframes Snackbar-slideOut-toTop {
  from {
    @include Snackbar-defaultTransform;
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -$Snackbar-outOffset-vertical, 0);
  }
}

.Toastify__slide-enter--bottom-center {
  animation-name: Snackbar-slideIn-toTop;
}

@keyframes Snackbar-slideIn-toTop {
  from {
    visibility: visible;
    transform: translate3d(0, $Snackbar-outOffset-vertical, 0);
  }

  to {
    @include Snackbar-defaultTransform;
  }
}

.Toastify__slide-exit--bottom-center {
  animation-name: Snackbar-slideOut-toBottom;
}

@keyframes Snackbar-slideOut-toBottom {
  from {
    @include Snackbar-defaultTransform;
  }

  to {
    visibility: hidden;
    transform: translate3d(0, $Snackbar-outOffset-vertical, 0);
  }
}
