.Ranking__Header {
  justify-content: space-between;
}

.Ranking__Header__selects {
  display: flex;
  > *:not(:last-child) {
    margin-right: 24px;
  }
}

.Ranking--empty {
  line-height: 55vh;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: $color-mid-grey;
}
