.SummaryPanel {
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  background-color: $color-white;
  padding: 20px 20px 30px 16px;
}

.SummaryPanel__category {
  margin-bottom: 40px;
}

.SummaryPanel__category__title {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.83;
  letter-spacing: 1.8px;
  color: #333333;
  margin-bottom: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
}

// .SummaryPanel__category__title--bold {
//   @include SummaryPanel__category__title;
// }

.SummaryPanel__category__value {
  height: 30px;
  padding-bottom: 6px;
  margin-bottom: 15px;
  border-bottom: 1px solid $color-light-grey;
  display: flex;
  justify-content: space-between;
  min-width: 0;
}

.SummaryPanel__category__value--right {
  flex: 1;
}

.SummaryPanel__category__value--title {
  @extend .SummaryPanel__category__value;
  margin-bottom: 16px;
}

.SummaryPanel__category__value--percentage {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  text-align: right;
  color: $color-black;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.SummaryPanel__category__value--count {
  width: 3.2em;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  text-align: right;
  color: $color-black;
  margin-left: 10px;
  flex: 0.4;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.SummaryPanel__category__value__title {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #353535;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.SummaryPanel__category__value__title--bold {
  @extend .SummaryPanel__category__value__title;
  font-weight: 600;
}
