.TitleType {
  text-transform: uppercase;
  margin-left: 10px;
  font-family: $typography-metric;
  font-size: 12px;
  font-weight: 500;
}

.TitleType--column {
  margin-left: 0;
  font-size: 14px;
}

.TitleType--back {
  color: $color-orange;
}

.TitleType--new {
  color: $color-blue;
}
