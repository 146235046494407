.ExpertFiltersFilter {
  margin-top: 24px;
  margin: 0 -14px;
  padding: 14px;
}

.ExpertFiltersFilter:hover {
  background-color: $color-light-grey;
}
.ExpertFiltersFilter__icon {
  font-weight: bold;
  opacity: 0;
  margin-left: 8px;
}
.ExpertFiltersFilter__remove {
  @extend .ExpertFiltersFilter__icon;
  color: $color-red;
}

.ExpertFiltersFilter:hover .ExpertFiltersFilter__remove,
.ExpertFiltersFilter:hover .ExpertFiltersFilter__edit {
  opacity: 1;
}

.ExpertFiltersFilter__edit {
  @extend .ExpertFiltersFilter__icon;
  color: $color-mid-grey;
}

.ExpertFiltersFilter__title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: $color-blue;
  margin-bottom: 4px;
}

.ExpertFiltersFilter__desc {
  font-size: 14px;
  color: $color-dark-grey;
}
