.Expert__Header {
  justify-content: space-between;
}

.Expert__Header__selects {
  display: flex;
  > *:not(:last-child) {
    margin-right: 24px;
  }
}

.Expert__selections {
  margin-left: auto;
}

.Expert__search {
  margin-right: 24px;
}

.Expert__wrapper {
  display: flex;
}

.Expert__table {
  width: unset;
  flex: 1;
  min-width: 0;
}

.Expert__toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-weight: 600;
  font-size: 14px;
  color: $color-black;
  text-transform: initial;
}

@import './ExpertFilters.scss';
@import './ExpertFiltersForm.scss';
@import './ExpertFiltersFilter.scss';
@import './ExpertSaveModal.scss';
