$Snackbar-spacing: 1rem;

.Snackbar {
  position: relative;
  min-width: 360px;
  height: 48px;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 16px;

  // Needed to avoid having the progress bar overflowing the border-radius.
  overflow: hidden;

  background: $color-dark-grey;
  color: $color-white;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: $Snackbar-spacing;
}

.Snackbar__body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Snackbar__progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: $color-blue;
  animation: Snackbar-progressBarAnimation linear 1;
}

@keyframes Snackbar-progressBarAnimation {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@import './SnackbarContainer';
@import './SnackbarContent';
@import './SnackbarMessage';
@import './toastifyOverrides';
