/* Speeds*/
// Views
$speed-in: 300ms;
$speed-out: 250ms;
//Controllers
$speed-on: 150ms;
$speed-off: 100ms;

/*Easings*/
$easing-default: cubic-bezier(0.4, 0, 0.2, 1);
