.BreadcrumbWrapper {
  position: relative;
  max-width: 100%;
  display: inline-flex;
}

.Breadcrumb {
  color: get-color('grey', light);
  list-style: none;
  display: inline-flex;
  align-items: center;
  height: 20px;
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  opacity: 1;
  pointer-events: all;
}

@import './BreadcrumbItem.scss';
