$height: 36px;
$width: 36px;

.TinyPlayer {
  position: relative;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: $height;
  width: $width;
  min-width: $width;
  cursor: pointer;
}

.TinyPlayer--column {
  height: $height * 1.5;
  width: $width * 1.5;
  min-width: $width * 1.5;
}
.TinyPlayer__icon {
  z-index: 10;
  font-size: 16px;
  color: white;
  opacity: 0.5;
  transition: all 150ms ease-in-out;
}

.TinyPlayer__close {
  font-size: 16px;
  position: absolute;
  background-color: $color-white;
  border-radius: 100%;
  border: 3px solid $color-white;
  padding: 2px;
  bottom: $height * 1.25;
  left: $width * 1.25;
  color: $color-mid-grey;
  opacity: 0;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.TinyPlayer:hover {
  .TinyPlayer__icon {
    opacity: 1;
  }
  .TinyPlayer__close {
    opacity: 1;
  }
}

.TinyPlayer__progress {
  position: absolute;
  z-index: 9;
  background-color: $color-blue;
  opacity: 0.75;
  width: 100%;
  bottom: 0;
  left: 0;
}

.TinyPlayer__audio {
  display: none;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  width: 0;
  height: 0;
  max-width: 0;
  max-height: 0;
}
