.RichTable__totalRow {
  border-top: none;
}

.RichTable__totalValue {
  font-size: 12px;
  font-weight: 600;
  color: $color-dark-grey;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  padding-left: 0;
}

.RichTable__actions {
  font-size: 14px;
  font-weight: 600;
  position: relative;
  padding-right: 0;
}

.RichTable__actionsIcon {
  font-size: 10px;
}

.RichTable__dropdownColumns {
  position: absolute;
  right: 0;
  left: unset;
  width: 220px;
}

.RichTable__average {
  height: 60px;
}

.RichTable__averageTitle {
  font-size: 16px;
  font-weight: bold;
}

.RichTable__averageValue {
  font-weight: normal;
  color: $color-blue;
  font-size: 20px;
  font-family: $typography-metric;
}

.RichTable__deviationTitle {
  font-size: 14px;
  font-weight: bold;
}

.RichTable__deviationValue {
  font-weight: normal;
  font-size: 14px;
  font-family: $typography-metric;
}

.RichTable--empty {
  line-height: 55vh;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: $color-mid-grey;
}
