.Title__header {
  display: flex;
  background-color: $color-light-grey;
  width: 100%;
  height: 230px;
  padding: 24px 34px;
  margin-top: 24px;
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
}

.Title__header__cover__infos {
  display: flex;
}

.Title__cover {
  min-width: 178px;
  height: 178px;
}

.Title__infos {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
}

.Title__infos__title {
  font-family: $typography-base;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: $color-dark-grey;
}

.Title__infos__title__wrapper {
  display: flex;
  align-items: center;
}

.Title__infos__artist {
  font-family: $typography-base;
  font-size: 16px;
  line-height: 1.5;
  color: $color-dark-grey;
}

.Title__infos__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
}

.Title__infos__tag {
  display: inline-flex;
  align-items: center;
  background-color: $color-mid-grey;
  padding: 3px 16px;
  height: 20px;
  border-radius: 10px;
  color: $color-white;
  text-transform: uppercase;
  font-family: $typography-base;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
  margin-right: 1em;
  margin-bottom: 1em;
}

.Title__infos__occurence {
  margin-top: 0.5em;
  font-family: $typography-base;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.83;
  letter-spacing: 1px;
  color: $color-dark-grey;
}

.Title__Table__segment {
  font-family: $typography-base;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-dark-grey;
}

.Title__Table__segment--pink {
  color: $color-blue;
}

.Title__Row__Select {
  padding-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title__switcher--active {
  color: $color-blue;
}

.Title__wave {
  top: 201px - (46px/2);
  left: 230px;
  position: absolute;
  width: calc(100% - 230px);
}

.Title__evolution__chart__wrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Title__evolution__legend__score {
  display: flex;
  padding: 16px;
  cursor: pointer;
}

.Title__evolution__legend__score__color {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 8px;
  background-color: $color-light-grey;
}

.Title__evolution__legend__score__name {
  font-family: $typography-base;
  font-size: 14px;
}

.Title__evolution__legend__score__name--selected {
  font-weight: 600;
}

.Title__evolution__legend__title {
  padding-left: 16px;
  font-family: $typography-base;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-dark-grey;
}

.Title__evolution__chart {
  height: 500px;
  width: 80%;
  position: relative;
}

.waveform {
  height: 23px;
  overflow: hidden;
}

wave {
  height: 46px !important;
}

.Title__waveDate {
  margin-top: 4px;
  font-weight: 600;
}

.Title--empty {
  animation: slowDisplay 0s 1s forwards;
  opacity: 0;
  line-height: 55vh;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: $color-mid-grey;
}

@keyframes slowDisplay {
  to {
    opacity: 1;
  }
}

.Segmented_controller {
  display: flex;
}

.Segmented_controller__segment {
  display: flex;
  padding: 16px 24px;
  border: 1px solid $color-input;
  border-left: none;
  color: $color-mid-grey;
  cursor: pointer;
  &:last-child {
    border-radius: 0 8px 8px 0;
  }
  &:first-child {
    border-left: 1px solid $color-input;
    border-radius: 8px 0 0 8px;
  }
}

.Segmented_controller__segment--active {
  color: $color-blue;
}
