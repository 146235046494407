.TableCell {
  padding: 0 $Table-cell-spacing;
  display: flex;
  align-items: center;
}

.TableCell--with-weight {
  min-width: 0;
  flex: 1;
}

.TableCell--align-left {
  justify-content: flex-start;
  text-align: left;
}

.TableCell--align-center {
  justify-content: center;
  text-align: center;
}

.TableCell--align-right {
  justify-content: flex-end;
  text-align: right;
}

.TableCell--section-head {
  font-weight: 500;
}
