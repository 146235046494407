.Progress {
  height: 10px;
  border-radius: 6px;
}

.Progress--pink {
  background-image: linear-gradient(149deg, $color-blue 0%, $color-dust 100%);
}

.Progress--orange {
  background-image: linear-gradient(
    149deg,
    $color-gradient-1 0%,
    $color-dust 100%
  );
}

.Table--compact .Progress {
  height: 8px;
}
