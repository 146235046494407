.Wave {
  padding-left: $sidebar_width + $padding_page;
  padding-right: $padding_page;
}

@include responsive-tablet {
  .Wave {
    padding-left: $sidebar_small_width + $padding_small_page;
    padding-right: $padding_small_page;
  }
}
