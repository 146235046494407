.Chip--purple {
  background-color: $color-blue;
  color: $color-white;
}

.Chip--purple--active,
.Chip--purple--clickable:hover,
.Chip--purple:focus {
  background-color: mix($color-white, $color-blue, 30%);
}
