.Placeholder {
  min-height: 0.8rem;
  line-height: 1em;
  width: 75%;
  display: inline-block;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: Placeholder-loadingAnimation;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    rgba(237, 237, 237, 0.2),
    rgba(129, 129, 129, 0.2) 50%,
    rgba(237, 237, 237, 0.2)
  );
  background-size: 1000px auto;
  border-radius: 50px;
}

@keyframes Placeholder-loadingAnimation {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
