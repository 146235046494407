.TableCellText {
  min-width: 0;
  max-width: 100%;
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 18px;
  line-height: 18px;
  margin-right: $Table-cell-spacing;
}
