.Echantillon__Card {
  position: relative;
  height: 450px;
}

.Echantillon__header {
  align-items: center;
  justify-content: center;
}

.Echantillon__header__Icon {
  margin: 0 24px;
  font-size: 16px;
}

.Echantillon__Summary {
  margin-top: 24px;
}
