.Sidebar {
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  width: $sidebar_width;
  overflow-y: auto;
  border-right: 1px solid $color-light-grey;
  background-color: $color-white;
}

.Sidebar__top {
  margin-top: 24px;
}

.Sidebar__top__logo {
  display: block;
  background-image: url('../../assets/logos/hyperworld.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 48px;
  width: 189px;
  margin: 0 36px;
}

.Sidebar__list {
  margin-top: 24px;
}
.Sidebar__subitem,
.Sidebar__item {
  width: 100%;
  font-size: 15px;
  cursor: pointer;
  padding: 0 31px;
  color: $color-dark-grey;
  height: 54px;
  display: flex;
  align-items: center;
}

.Sidebar__item:hover,
.Sidebar__item--active {
  color: $color-blue;
  font-weight: bold;
}

.Sidebar__item--active {
  border-left: 4px solid $color-blue;
  padding-left: 27px;
}

.Sidebar__item__link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Sidebar__item__icon {
  font-size: 18px;
  margin-right: 24px;
}

.Sidebar__subitem {
  font-size: 14px;
  height: 28px;
}

.Sidebar__subitem > * {
  line-height: 28px;
  display: inline;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Sidebar__subitem--active,
.Sidebar__subitem:hover {
  font-weight: bold;
}
.Sidebar__subitem__link {
  flex: 1;
}

.Sidebar__subitem__icon {
  line-height: 28px;
  font-size: 4px;
  margin-left: 4px;
  margin-right: 34px;
}

.Sidebar__bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: $sidebar_width;
  height: 62px;
  background-color: $color-light-grey;
}

.Sidebar__subitem__actions {
  margin-left: 8px;
  display: none;
}

.Sidebar__subitem:hover > .Sidebar__subitem__actions {
  display: block;
}

.Sidebar__subitem__actions > * {
  opacity: 0.8;
}

.Sidebar__subitem__actions > *:hover {
  opacity: 1;
}

.Sidebar__subitem__actions > *:last-child {
  color: $color-red;
}

.Sidebar__subitem__actions > *:not(:last-child) {
  margin-right: 8px;
}

@include responsive-tablet {
  .Sidebar:not(:hover) {
    width: $sidebar_small_width;
    .Sidebar__subitem {
      display: none;
    }
    .Sidebar__bottom {
      width: $sidebar_small_width;
    }
    .Sidebar__bottom__text {
      display: none;
    }

    > .Sidebar__top {
      > .Sidebar__top__logo {
        background-image: url('../../assets/logos/light.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 38px;
        width: 43px;
        margin: 8px 16px 38px;
      }
    }
  }
}
