.MultipleLineChartTooltip {
  overflow: hidden;
  width: 240px;
  background-color: $color-black;
  border-radius: 8px;
}

.MultipleLineChartTooltip__title {
  font-weight: 600;
  background-color: #3f3f3f;
  padding: 16px 16px;
  text-align: left;
  font-family: $typography-base;
  font-size: 16px;
  font-weight: bold;
}

.MultipleLineChartTooltip__values__wrapper {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  font-family: $typography-base;
  font-size: 14px;
  font-weight: normal;
}

.MultipleLineChartTooltip__values {
  display: flex;
  justify-content: space-between;
  line-height: 2em;
}

.MultipleLineChartTooltip__key {
  display: flex;
  flex: 4;
}

.MultipleLineChartTooltip__value {
  display: flex;
  flex: 1;
  font-family: $typography-base;
  font-size: 12px;
  font-weight: 600;
  text-align: right;
}

.MultipleLineChart__Axis__ticks {
  font-family: $typography-base;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.83;
  letter-spacing: 1.8px;
  fill: $color-light-grey;
  // outline: solid 3px$color-blue;
}
