.SegmentsVersus__header {
  justify-content: space-between;
}

.SegmentsVersus__header__left {
  display: flex;
  > *:not(:last-child) {
    margin-right: 24px;
  }
}
.SegmentsVersus__header__left__versus {
  margin-left: 56px;
  display: flex;
}

.SegmentsVersus__header__left__versus__switch {
  display: flex;
  align-items: center;
}

.SegmentsVersus__header__left__versus__switch button {
  @extend %reset-button-styles;
  cursor: pointer;
  font-size: 18px;
  color: $color-mid-grey;
  padding: 0px 18px;
}
