.Card {
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  background-color: $color-white;
  padding: 15px;
  margin-bottom: 30px;
}

.Card__header {
  font-family: $typography-base;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.8px;
  color: $color-dark-grey;
  text-transform: uppercase;
}
