.Variation {
  font-family: $typography-metric;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}

.Variation--still {
  padding: 0 14px;
}

.Variation--up {
  color: $color-green;
}

.Variation--down {
  color: $color-red;
}

.Variation__icon {
  font-size: 20px;
}
