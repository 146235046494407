.TableRowTitle {
  flex: none;
  display: flex;
  align-items: stretch;
  padding: 0 $Table-cell-spacing;
  height: 62px;
  border-top: solid 1px $color-light-grey;
}

.TableRowTitle__cell {
  margin-top: 6px;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 1.8px;
  color: $color-blue;
}
