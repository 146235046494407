.Donut__Legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
}

.Donut__Legend__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:last-child) {
    padding-right: 16px;
  }
}

.Donut__Legend__item__label {
  line-height: 1.5;
  text-transform: capitalize;
}

.Donut__Legend__item__color {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin-right: 8px;
}
