.Chip {
  margin: 0;
  border: none;
  padding: 0;
  background: none;
  color: inherit;
  text-align: left;
  font: inherit;
  user-select: none;
  cursor: pointer;
  outline: none;
  border-radius: 16px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  cursor: initial;
  transition: background-color linear 50ms;
}

.Chip--medium {
  height: 32px;
  padding: 0 14px;
}

.Chip--small {
  height: 24px;
  padding: 0 10px;
}

.Chip--clickable {
  cursor: pointer;
  user-select: none;
}

.Chip > *:not(:first-child) {
  margin-left: 8px;
}

.Chip__removeIcon {
  flex: none;
  cursor: pointer;
  color: $color-black;
  opacity: 0.3;
}

.Chip__icon {
  flex: none;
}

.Chip__label {
  flex: 1;
}

.Chip__richContent {
  flex: 1;
  min-width: 0;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

@import 'colors/purple';
@import 'colors/light-grey';
