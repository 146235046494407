.Trends__header {
  justify-content: space-between;
}
.Trends__header__selects {
  display: flex;
  > *:not(:last-child) {
    margin-right: 24px;
  }
}

.Trends__Table {
  margin-top: 22px;
}

.Trends__Table__wave {
  display: flex;
  flex-direction: column;
}

.Trends__Table__wave__minus {
  font-family: $typography-metric;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: $color-black;
}

.Trends__Table__wave__minus--current {
  @extend .Trends__Table__wave__minus;
  color: $color-blue;
}

.Trends__Table__wave__average {
  font-family: $typography-base;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: capitalize;
  color: $color-mid-grey;
}

.Trends__waveSecondDate {
  margin-top: 4px;
}

.Trends--empty {
  line-height: 55vh;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: $color-mid-grey;
}
