// `height` for top or bottom placements.
$Tooltip-arrowSize: 6px;

$Tooltip-borderRadius: 4px;
$Tooltip-backgroundColor: $color-dark-grey;

.Tooltip {
  z-index: 1200;
  max-width: 25vw;
  pointer-events: none;
  background-color: $Tooltip-backgroundColor;
  color: $color-white;
  border-radius: $Tooltip-borderRadius;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
}

.Tooltip--out-of-boundaries {
  opacity: 0;
}

@mixin arrow-placement($direction) {
  &[data-placement^='#{$direction}'] {
    #{$direction}: 100%;
    border-#{$direction}-color: $Tooltip-backgroundColor;
    margin-#{$direction}: 0;
  }
}

.Tooltip__arrow {
  position: absolute;
  display: flex;
  border-style: solid;
  border-width: $Tooltip-arrowSize;
  border-color: transparent;
  height: 0;
  width: 0;

  // It looks better with 1px less.
  margin: $Tooltip-borderRadius - 1;

  @include arrow-placement(top);
  @include arrow-placement(right);
  @include arrow-placement(bottom);
  @include arrow-placement(left);
}
