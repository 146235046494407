$ListItem-textColor: $color-dark-grey;
$ListItem-textColorDisabled: mix($color-dark-grey, transparent, 50%);

.ListItem {
  @extend %reset-button-styles;
  @extend %reset-anchor-styles;

  line-height: 1.57;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $ListItem-textColor;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}

.ListItem--highlighted {
  background-color: $color-input;
}

.ListItem--highlightOnHover:hover {
  @extend .ListItem--highlighted;
}

.ListItem > *:not(:first-child) {
  margin-left: 8px;
}

.ListItem--selected {
  font-weight: 600;
}

.ListItem--disabled {
  color: $ListItem-textColorDisabled;
  cursor: not-allowed;
}
