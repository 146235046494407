.ExpertFiltersForm__leave {
  @extend .ExpertFilters__title;
  display: inline-flex;
  align-items: center;
}

.ExpertFiltersForm__leave > i {
  font-size: 12px;
  margin-right: 8px;
}

.ExpertFiltersForm__field {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  margin-top: 24px;
}

.ExpertFiltersForm__submit {
  margin-left: auto;
  margin-top: 32px;
}
