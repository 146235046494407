.SelectionsActions {
  position: relative;
}

.SelectionsActions__actions {
  width: 240px;
  right: 0;
  left: unset;
}

@import './SelectionsActionsModal.scss';
