.Modal__wrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  left: 0;
}

.Modal {
  background-color: $color-white;
  width: 440px;
  z-index: 1002;
  border-radius: 4px;
}

.Modal__overlay {
  background: $color-white;
  opacity: 0.8;
  z-index: 1001;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ModalTitle {
  font-family: $typography-base;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  color: $color-dark-grey;
  margin-bottom: 8px;
}
