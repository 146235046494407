.Dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 5px;
  z-index: 3;
  background-color: $color-white;
  font-size: 14px;
  margin-left: 1%;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.23);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.DropdownItem {
  @extend %reset-button-styles;
  font-size: 14px;
  font-family: 'Open sans';
  font-weight: 400;
  text-transform: none;
  box-sizing: border-box;
  text-align: left;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  padding-left: 13px;
  padding-right: 16px;
  text-decoration: none;
  color: $color-black;
  display: inline-flex;
  align-items: center;
  background-color: white;
  transition-property: background-color;
  transition-duration: $speed-off;
  transition-timing-function: $easing-default;

  &::first-letter {
    text-transform: uppercase;
  }
}

.Dropdown--highlighted,
.DropdownItem:hover {
  background-color: $color-input;
  transition-duration: $speed-on;
}

.DropdownItem--active {
  font-weight: 600;
}

.DropdownItem--disabled {
  color: $color-mid-grey;
}

.DropdownItem__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.DropdownItem__checkbox {
  margin-left: auto;
  color: $color-blue;
  text-align: right;
}
