.ExpertSaveModal {
  width: auto;
}
.ExpertSaveModal__input {
  margin-top: 24px;
  margin-bottom: 8px;
  min-width: 100%;
}

.ExpertSaveModal__toggle {
  display: flex;
  align-items: center;
  line-height: 40px;
  cursor: pointer;
}

.ExpertSaveModal__action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.ExpertSaveModal__action > *:not(:first-child) {
  margin-left: 12px;
}
