.App {
  background-color: $color-white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: $padding_page;
}

@include responsive-tablet {
  .App {
    padding-bottom: $padding_small_page;
  }
}
