@font-face {
    font-family: 'icons';
    src: url('./icons.eot');
    src: url('./icons.eot?#iefix') format('eot'),
        url('./icons.woff') format('woff'),
        url('./icons.ttf') format('truetype'),
        url('./icons.svg#icons') format('svg');
    font-display: block;
}

.Icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.Icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.Icon-add:before {
	content: "\E001";
}

.Icon-bubble:before {
	content: "\E002";
}

.Icon-bulb:before {
	content: "\E003";
}

.Icon-calendar:before {
	content: "\E004";
}

.Icon-cancel:before {
	content: "\E005";
}

.Icon-charts:before {
	content: "\E006";
}

.Icon-checkbox:before {
	content: "\E007";
}

.Icon-chevron-left:before {
	content: "\E008";
}

.Icon-chevron-right:before {
	content: "\E009";
}

.Icon-close:before {
	content: "\E00A";
}

.Icon-details:before {
	content: "\E00B";
}

.Icon-download:before {
	content: "\E00C";
}

.Icon-edit:before {
	content: "\E00D";
}

.Icon-expert:before {
	content: "\E00E";
}

.Icon-filter:before {
	content: "\E00F";
}

.Icon-home:before {
	content: "\E010";
}

.Icon-like-e:before {
	content: "\E011";
}

.Icon-like-f:before {
	content: "\E012";
}

.Icon-link:before {
	content: "\E013";
}

.Icon-list:before {
	content: "\E014";
}

.Icon-new:before {
	content: "\E015";
}

.Icon-pause:before {
	content: "\E016";
}

.Icon-play:before {
	content: "\E017";
}

.Icon-rank-down:before {
	content: "\E018";
}

.Icon-rank-keep:before {
	content: "\E019";
}

.Icon-rank-up:before {
	content: "\E01A";
}

.Icon-ranking:before {
	content: "\E01B";
}

.Icon-rename:before {
	content: "\E01C";
}

.Icon-rocket:before {
	content: "\E01D";
}

.Icon-save:before {
	content: "\E01E";
}

.Icon-scores:before {
	content: "\E01F";
}

.Icon-search:before {
	content: "\E020";
}

.Icon-selection-add copy:before {
	content: "\E021";
}

.Icon-selection-add:before {
	content: "\E022";
}

.Icon-selection-delete:before {
	content: "\E023";
}

.Icon-selection:before {
	content: "\E024";
}

.Icon-sort-asc:before {
	content: "\E025";
}

.Icon-sort-default:before {
	content: "\E026";
}

.Icon-sort-desc:before {
	content: "\E027";
}

.Icon-switch:before {
	content: "\E028";
}

.Icon-tick:before {
	content: "\E029";
}

.Icon-trash:before {
	content: "\E02A";
}

.Icon-users:before {
	content: "\E02B";
}

.Icon-versus:before {
	content: "\E02C";
}
