.Topbar {
  height: 64px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 48px 0px 48px;
}

.Topbar--right {
  display: flex;
  align-items: center;
}

.Topbar--sidebar {
  padding-left: $sidebar_width + 70px;
}

.Topbar:after {
  position: absolute;
  content: '';
  border-bottom: 1px solid #e5e5e5;
  bottom: 0px;
  left: 0;
  right: 0;
}

.Topbar__logo {
  display: block;
  background-image: url('../../assets/logos/hyperworld.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 53px;
  width: 189px;
  margin-right: 2em;
}

.Topbar__user {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.Topbar__Dropdown {
  position: absolute;
  width: auto;
  min-width: 200px;
  right: 0;
  top: 18px;
  left: unset;
}

.Topbar__icon {
  margin-left: 10px;
  font-size: 10px;
}

.Topbar__toggle {
  margin-right: 0;
  margin-left: 36px;
}

@include responsive-tablet {
  .Topbar--sidebar {
    padding-left: $sidebar_small_width + $padding_small_page;
    padding-right: $padding_small_page;
  }
}
