.RoundedPlayer {
  position: relative;
  align-items: center;
  display: flex;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 50%;
  min-width: 22px;
  height: 22px;
  justify-content: center;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;

  .progress {
    position: absolute;
    left: -2px;
    transform: scale(0.15);
    transform-origin: left center;
    svg {
      transform: rotate(-90deg);
    }
  }
}

.RoundedPlayer__icon {
  font-size: 8px;
}
