.BreadcrumbItem {
  height: 100%;
  display: flex;
  align-items: center;
}

.BreadcrumbItem__content,
.BreadcrumbItem__content > * {
  @extend %reset-button-styles;
  @extend %reset-anchor-styles;
  color: $color-dark-grey;
  line-height: 20px;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.BreadcrumbItem__content::first-letter,
.BreadcrumbItem__content a::first-letter {
  text-transform: capitalize;
}

.BreadcrumbItem:last-child .BreadcrumbItem__content,
.BreadcrumbItem:last-child a {
  font-weight: 700;
  color: $color-dark-grey;
}

.BreadcrumbItem__separator {
  color: $color-dark-grey;
  font-size: 10px;
  margin: 0 8px;
}
