.WaveSwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.WaveSwitcher__chevron {
  font-size: 10px;
  cursor: pointer;
  padding: 8px;
}
.WaveSwitcher__chevron--disabled {
  opacity: 0.5;
  cursor: initial;
}
.WaveSwitcher__tick {
  color: $color-blue;
}

.WaveSwitcher__calendar {
  margin-right: 8px;
}

.WaveSwitcher__current {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  padding: 8px;
  border-radius: 6px;
}

.WaveSwitcher__current--open {
  color: $color-blue;
  background-color: fade-out($color-blue, 0.9);
}

.WaveSwitcher__current:first-child {
  margin-right: 8px;
}

.WaveSwitcher__Dropdown {
  position: absolute;
  min-width: 350px;
  right: -70px;
  top: 32px;
  left: unset;
}

.WaveSwitcher__DropdownItem .DropdownItem__label {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.WaveSwitcher__DropdownItem--active .DropdownItem__label > span {
  font-weight: 600;
}
