.Overview__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Overview__header__img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  width: 52px;
  background-color: $color-white;
  border-radius: 4px;
  padding: 4px;
}

.Overview__header__img img {
  width: 100%;
}

.Overview__header__infos__wave {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 16px;
}

.Overview__header__infos__wave__radio {
  font-family: $typography-base;
  font-weight: bold;
  color: $color-dark-grey;
  font-size: 20px;
  margin-bottom: 8px;
}

.Overview__header__infos__wave__name {
  font-family: $typography-base;
  color: $color-dark-grey;
  font-size: 16px;
}

.Overview__header__infos {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.Overview__infos {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Overview__info {
  width: 100%;
  font-family: $typography-metric;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: $color-dark-grey;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.Overview__donuts {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Overview__donut__Card {
  min-width: 0;
  height: 400px;
  width: 100%;
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.Overview__donut {
  min-width: 0;
  position: relative;
  width: 100%;
  height: 100%;
}

.Overview__Table__tag {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.8px;
  color: $color-blue;
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
}

.Overview__Table__value {
  display: flex;
  justify-content: flex-start;
  text-transform: capitalize;
  font-weight: 600;
  text-align: left;
}

.Overview__Table__average__title {
  @extend .Overview__Table__value;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $color-dark-grey;
  text-transform: inherit;
}

.Overview__Table__average__value {
  font-family: $typography-metric;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: $color-blue;
}

.Overview__Table__head__label {
  @include text-ellipsis;
  font-family: $typography-base;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.8px;
  color: $color-dark-grey;
  text-transform: uppercase;
}
