.SelectionsActionsModal__title {
  font-size: 16px;
  font-weight: 700;
  color: $color-dark-grey;
}

.SelectionsActionsModal__action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.SelectionsActionsModal__action > *:not(:first-child) {
  margin-left: 12px;
}
