.Segments__header {
  justify-content: space-between;
}

.Segments__header__selects {
  display: flex;
  > *:not(:last-child) {
    margin-right: 24px;
  }
}
