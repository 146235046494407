@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin multiline-text-ellipsis($lineCount: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  hyphens: auto;
}

// desktop first
@mixin responsive-tablet {
  @media screen and (max-width: $tablet-breakpoint) {
    @content;
  }
}
