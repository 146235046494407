.TableCellSortableText {
  @extend %reset-button-styles;
  min-width: 0;
  max-width: 100%;
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-family: $typography-base;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.8px;
  color: $color-dark-grey;
  text-transform: uppercase;
}

.TableCellSortableText:not(:first-child) {
  margin-left: $Table-cell-spacing * 2;
}

.TableCellSortableText .TableCellSortableText__sortIcon {
  position: absolute;
  right: 10px;
  transform: translateX(100%);
  display: inline-flex;
  color: $color-dark-grey;
}

.TableCellSortableText--noIcon .TableCellText {
  margin: 0;
}
