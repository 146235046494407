.TableRow {
  flex: none;
  display: flex;
  align-items: stretch;
  padding: 0;
  height: 56px;
  border-top: solid 1px $color-light-grey;
}

.TableRow--hover {
  transition: background-color 50ms ease-in-out;
  background-color: #fff;
}

.TableRow--hover:hover {
  background-color: #f8f8f8;
}

.TableRow--selected {
  box-shadow: inset 4px 0 0 0 $color-blue;
}

.TableRow--disabled > * {
  opacity: 0.5;
}

.Table--compact .TableRow {
  height: 30px;
}

.Table--compact .TableRow--section-head,
.TableRow--section-head {
  height: 60px;
}
