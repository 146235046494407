.Compare__header {
  justify-content: space-between;
}

.Compare__header__actions {
  display: flex;
  > *:first-child {
    margin-left: 24px;
  }
}

.Compare__header__actions > *:not(:first-child) {
  margin-left: 16px;
}

.Compare__AddModal {
  width: auto;
  min-width: 700px;
}

.Compare__AddModal__Input {
  width: 100%;
  max-width: 100%;
}

.Compare__AddModal__Titles {
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.Compare__AddModal__Title {
  display: flex;
  align-items: center;
  border-top: 1px solid $color-light-grey;
  height: 56px;
  cursor: pointer;
  &:hover {
    background-color: $color-light-grey;
  }
}

.Compare__deleteTitles {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100px;
}

.Compare__deleteTitles__button {
  font-family: $typography-base;
  font-size: 14px;
  font-weight: 600;
  color: $color-dark-grey;
  cursor: pointer;
}

.Compare__AddModal__Title__Player {
  margin-right: 8px;
}

.Compare__AddModal__Title__checkbox {
  cursor: pointer;
}

.Compare__AddModal__Footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Compare__Table__Title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding-top: 24px;
  height: 150px;
}

.Compare__Table__Title__Icon {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  cursor: pointer;
}

.Compare__Table__Header {
  height: 150px;
  border: none;
}

.Compare__Table__Title__name {
  margin-left: 0px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Compare__Table__Title__name b {
  font-weight: bold;
}

.Compare__Table__Header__left {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: $color-dark-grey;
}

.Compare__Table--Empty {
  width: 100%;
  line-height: 55vh;
  text-align: center;
  font-size: 32px;
  font-weight: 300;
  color: $color-mid-grey;
}

.Compare__Table__Title--pointer {
  cursor: pointer;
}

.Compare__Table__Title--selected {
  color: $color-blue;
  .Icon {
    color: initial;
  }
}
