$Toggle-width: 40px;
$Toggle-height: 20px;

%Toggle-elements {
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  transition: all 100ms ease-in-out;
}

.Toggle {
  display: inline-block;
  position: relative;
  width: $Toggle-width;
  height: $Toggle-height;
  margin-right: 12px;
}

.Toggle__input {
  margin: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  opacity: 0;
  outline: none;
  cursor: pointer;
}

.Toggle__input:disabled {
  cursor: not-allowed;
}

.Toggle__span {
  user-select: none;
  pointer-events: none;
}

.Toggle__span::before {
  @extend %Toggle-elements;
  transform: translateY(-50%);
  width: $Toggle-width;
  height: 14px;
  background-color: #e5e5e5;
  border-radius: 8px;
}

.Toggle__span::after {
  @extend %Toggle-elements;
  transform: translate(0, -50%);
  width: $Toggle-height;
  height: $Toggle-height;
  background-color: $color-white;
  border-radius: 50%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.23);
}

.Toggle__input:disabled + .Toggle__span {
  opacity: 0.5;
}

.Toggle__input:checked + .Toggle__span::before {
  background-color: mix($color-blue, transparent, 50%);
}

.Toggle__input:checked + .Toggle__span::after {
  background-color: $color-blue;
  transform: translate(100%, -50%);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.23);
}
